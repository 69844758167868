'use client'

import { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
  UserIcon,      
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon, } from '@heroicons/react/20/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { logo, smartVoiceControl } from '../../assets/index'
import { Link } from 'react-router-dom'

const products = [
  { name: 'IOT Edge Gateway', description: 'Explore seamless integration, reliable control, and advanced security for everything.', href: '/smart-things', icon: ChartPieIcon },
  { name: 'General Features', description: 'Explore the key features that drive our IOT solutions', href: '/general-features', icon: CursorArrowRaysIcon },
  { name: 'Operating System Development', description: 'Development of safe and secure operating systems', href: '/operating-system-development', icon: FingerPrintIcon },
  { name: 'IoT Development Services', description: 'Development of most efficient IOT solutions', href: '/iot-development-services', icon: SquaresPlusIcon },
  { name: 'SMART', description: 'Smart with Mainlogix', href: '/smart', icon: ArrowPathIcon },
]
const callsToAction = [
  { name: 'About Smart', href: '#', icon: PlayCircleIcon },
  { name: 'Consultation', href: '/contact', icon: PhoneIcon },
]

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [activeLink, setActiveLink] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null); // Reference to the popup modal

  // Function to open the video popup
  const openVideoPopup = () => {
    setShowPopup(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  // Handle closing on escape key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        closePopup();
      }
    };

    // Add event listener for keydown
    document.addEventListener('keydown', handleEscape);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  // Handle closing the popup when clicking outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <header className="bg-white" >
      <nav aria-label="Global" className="flex max-w-[100%] items-center justify-between p-6 lg:px-8">
        <div className="logo-container flex lg:flex-1 lg:justify-center">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Mainlogix</span>
            <img alt="" src={logo} className="smd:h-8 h-16 w-auto 2xl:h-20 4xl:h-32" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        
        <PopoverGroup className="hidden lg:flex lg:flex-1 lg:justify-center lg:gap-x-8">

          <Link to="/" 
            className={`nav-link nav-text-bck 2xl:text-lg 4xl:text-3xl  ${activeLink === 'Home' ? 'nav-link-active' : ''}`}
            onClick={() => handleLinkClick('Home')}
          >
            Home
          </Link>

          <Link to="/services" 
            className={`nav-link nav-text-bck 2xl:text-lg 4xl:text-3xl ${activeLink === 'Services' ? 'nav-link-active' : ''}`}
            onClick={() => handleLinkClick('Services')}
          >
            Services
          </Link>
          <Link to="/products" 
            className={`nav-link nav-text-bck 2xl:text-lg 4xl:text-3xl ${activeLink === 'Product' ? 'nav-link-active' : ''}`}
            onClick={() => handleLinkClick('Product')}
          >
            Products
          </Link>

          
          <Link to="/expertise" 
            className={`nav-link nav-text-bck 2xl:text-lg 4xl:text-3xl ${activeLink === 'Expertise' ? 'nav-link-active' : ''}`}
            onClick={() => handleLinkClick('Expertise')}
            >
            Expertise
          </Link>
          {/* <Link to="/existing-solutions" className="nav-link nav-text-bck 2xl:text-lg 4xl:text-3xl">
            Solutions
          </Link> */}

          <Popover 
            className={`nav-link relative ${activeLink === 'Product' ? 'nav-link-active' : ''}`}
            >
            <PopoverButton 
              
              className="nav-text-bck  2xl:text-lg 4xl:text-3xl flex items-center gap-x-1 font-semibold leading-6 text-gray-900 
                   focus:outline-none focus:ring-0 focus:border-transparent 
                   focus-visible:outline-none focus-visible:ring-0 focus-visible:border-transparent
                   active:border-transparent hover:border-transparent"
              // onClick={() => handleLinkClick('Product')}
            
              >
              Solutions
              <FontAwesomeIcon icon={faCaretDown} />
              </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {products.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-red-600" />
                    </div>
                    <div className="flex-auto">
                      <Link to={item.href} className="block font-semibold 2xl:text-lg 3xl:text-2xl 4xl:text-3xl text-gray-900">
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                      <p className="mt-1 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <div  onClick={openVideoPopup}>
                    <Link
                      key={item.name}
                      to={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                      {item.name}
                    </Link>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>


          <Link to="/technologies-tools" className="nav-link nav-text-bck 2xl:text-lg 4xl:text-3xl">
            Technologies & Tools
          </Link>
        </PopoverGroup>
        <div className="hidden header-right lg:flex lg:flex-1 lg:items-center lg:justify-end">
           
          <Link to="/contact" className="nav-text-wht text-white bg-red-600 rounded-full px-4 py-2 xlarge:text-[1rem] 2xl:text-lg 4xl:text-3xl xl:text-[0.7rem] xlarge:px-4 xl:px-2 2xl:px-8 2xl:py-4">
            Contact & Consultation
          </Link>
          <UserIcon className="h-6 w-6 2xl:h-12 2xl:w-12 text-gray-800 hover:text-gray-500 dark:hover:text-gray-300 lg:mx-3" />
          <MagnifyingGlassIcon className="h-6 w-6 2xl:h-12 2xl:w-12 text-gray-800 hover:text-gray-600 dark:hover:text-gray-300 lg:mx-3" />
          
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src={logo}
                className="h-8 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Home
                </Link>
                <Link
                  to="/Services"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Services
                </Link>
                <Link
                  to="/products"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Products
                </Link>
                <Link
                  to="/expertise"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Expertise
                </Link>
                {/* <Link
                  to="/existing-solutions"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Solutions
                </Link> */}

                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Solutions
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    <div  onClick={openVideoPopup}>
                      {[...products, ...callsToAction].map((item) => (
                        <DisclosureButton
                          key={item.name}
                          as="a"
                          to={item.href}
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </DisclosureButton>
                      ))}
                    </div>
                  </DisclosurePanel>
                </Disclosure>

                <Link
                  to="/technologies-tools"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Technologies & Tools
                </Link>
                <Link
                  to="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact & Consultation
                </Link>
              </div>
              <div className="py-6 flex justify-around items-center">
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  <UserIcon className="h-6 w-6 text-gray-800 hover:text-gray-500 dark:hover:text-gray-300 lg:mx-3" />
          
          
                </Link>
                <Link  
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                  <MagnifyingGlassIcon className="h-6 w-6 text-gray-800 hover:text-gray-600 dark:hover:text-gray-300 lg:mx-3" />
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      <hr className='bg-gray-50'/>

      {/* Video Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          {/* Modal Content */}
          <div
            ref={popupRef}
            className="relative rounded-lg overflow-hidden w-full max-w-3xl mx-5 sm:mx-10"
          >
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={closePopup}
            >
              X
            </button>

            {/* Video */}
            <div className="w-full h-full flex justify-center items-center  p-4">
              <video className="rounded-2xl w-full max-h-[75vh]" controls autoPlay>
                <source src={smartVoiceControl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}

    </header>
  )
}

