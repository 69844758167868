import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PdtDetails from './pages/PdtDetails';
import Expertise from './pages/Expertise';
import Contact from './pages/Contact';
import GeneralFeatures from './pages/GeneralFeatures';
import IOTUseCases from './pages/IotUseCases';
import TechTools from './pages/TechTools';
import ExistingSolutions from './pages/ExistingSolutions';
import IotDevelopmentServices from './pages/IotDevelopmentServices';
import IotServices from './pages/IotServices';
import IotProductDevSteps from './pages/IotProductDevSteps';
import OptSysDev from './pages/OptSysDev';
import Products from './pages/Products';
import Smart from './pages/Smart';
import ScrollToTop from './components/ScrollToTop';
import Partners from './pages/Partners';
import PetIot from './pages/PetIot';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Services from './pages/Services';
import SmartThings from './pages/SmartThings';
import ConsultingServices from './pages/ConsultingServices';
import NfcMainlogix from './pages/NfcMainlogix';
import Aiot from './pages/Aiot';
import IotDataAnalytics from './pages/IotDataAnalytic';
import MachineLearningServices from './pages/MachineLearningServices';
import IotManageServices from './pages/IotManageServices';
import BookACall from './components/BookACall';
import GeolocationRedirect from './components/GeolocationRedirect';
import NotFound from './pages/NotFound';




function App() {
  return (
    <div>
      <GeolocationRedirect />
      <Router>
        <ScrollToTop />
        <BookACall />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/:id" element={<PdtDetails />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/general-features' element={<GeneralFeatures />} />
          <Route path='/iot-use-cases' element={<IOTUseCases />} />
          <Route path='/technologies-tools' element={<TechTools />} />
          <Route path='/existing-solutions' element={<ExistingSolutions />} />
          <Route path='/iot-development-services' element={<IotDevelopmentServices />} />
          <Route path='/iot-services' element={<IotServices />} />
          <Route path='/iot-product-development-step' element={<IotProductDevSteps />} />
          <Route path='/operating-system-development' element={<OptSysDev />} />
          <Route path='/products' element={<Products />} />
          <Route path='/services' element={<Services />} />
          <Route path='/smart' element={<Smart />} />
          <Route path='smart-things' element={<SmartThings />} />
          <Route path='/partners' element={<Partners />} />
          <Route path='/pets-iot' element={<PetIot />} />
          <Route path='/about' element={<About />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/consulting-services' element={<ConsultingServices />} />
          <Route path='/nfc' element={<NfcMainlogix />} />
          <Route path='/aiot' element={<Aiot />} />
          <Route path='/iot-data-and-analytics' element={<IotDataAnalytics />} />
          <Route path='/iot-machine-learning-services' element={<MachineLearningServices />} />
          <Route path='/iot-managed-services' element={<IotManageServices />} />
          {/* 404/undefined page route */}
          <Route path='*' element={<NotFound />} />
          {/* End of 404 undefined page route */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
