// // import React from "react";
// // import Header from "../components/home/Header";
// // import Footers from "../components/home/Footers";
// // import { achievement1, achievement2, gatewayNew, gatewayNew5, grandeurmartBenefits, grandeursmartFeatures, introBg, iotAppDevelopment, noImage, rto1, } from "../assets";
// // import { grandeursmartMultiprotocolData, grandeurSmartServices, grandeursmartUseCasesData, smartBenefit2Data, smartBenefitData, smartData } from "../data/data1";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { Helmet } from 'react-helmet-async';
// // import { faGlobe } from "@fortawesome/free-solid-svg-icons";
// // import { rtosDevelopmentData } from "../data/data3";
// // import { Link } from "react-router-dom";


// // export default function SmartThings(){

// // const product = smartBenefitData[1];

// //     return (
// //         <div className="bg-slate-100" >
// //             <Helmet>
// //                 <title>SMART by Mainlogix | AI Personalized Voice Assistant</title>
// //                 <meta name="description" content="Discover SMART by Mainlogix, an advanced AI personalized voice assistant that transforms your smart living experience. With features like voice-controlled music playback, smart light management, and the ability to distinguish between humans and animals, SMART delivers seamless interaction with your home and city devices. Explore our versatile IoT gateways, including LoRaWAN to Ethernet/Wi-Fi Gateway with backup battery and Multi-Protocol Smart Gateway with Li-Battery power backup, ensuring reliable connectivity and security for your smart environment." />
// //             </Helmet>

// //             <Header />

// //             <div className="relative w-full ">
// //                 <div
// //                     className="absolute inset-0 bg-cover bg-center"
// //                     style={{ backgroundImage: `url(${introBg})` }}
// //                 >
// //                     <div className="absolute inset-0 bg-black bg-opacity-75"></div>
// //                 </div>
// //                 <div className="relative page-title flex items-center justify-between  h-full smd:px-3 px-[10%] smd:py-10 py-20 ">
                
// //                     <div >
// //                         <h2 className="title-text font-poppins font-semibold text-left text-[55px] text-white">
// //                             Mainlogix IOT Edge Gateway
// //                         </h2>
// //                         <p className="text-white text-left mt-4">
// //                             Your Best Smart Gateway For All IOT Use Cases
// //                         </p>
// //                         <div className="flex justify-start my-5">
// //                             <Link to="/contact" >
// //                                 <button className='button-m text-center'>
// //                                     Download Technical Document
// //                                 </button>
// //                             </Link>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>

            
// //             <div className="bg-white py-10">
// //                 <h1>Mainlogix IOT Edge Gateway </h1>
// //                 <p className="text-center smd:px-3 px-[15%] pb-0">
// //                     Our IOT Edge Gateway is designed 
// //                     to bring all your smart devices together in one easy-to-use 
// //                     platform. Whether you're automating your home, managing a 
// //                     building, or looking for powerful IoT solutions, GrandeurSmart 
// //                     Gateway provides seamless integration, reliable control, and 
// //                     advanced security for everything from small homes to large 
// //                     estates.
// //                 </p>
                

// //                 <div className="smd:mx-3 mx-12 mt-5 rounded-xl 2xl:rounded-3xl overflow-hidden border-b-neutral-600 border-[1px] mb-10">

// //                     <div className="grid smd:grid-cols-1 grid-cols-2">
// //                         <div className="flex justify-center overflow-hidden bg-white">
// //                             <img 
// //                                 src={grandeursmartFeatures}
// //                                 className="object-cover" 
// //                                 alt="Our solutions" 
// //                             />
// //                         </div>
// //                         <div>
// //                             <div>   
// //                                 <div className="px-5"  >
// //                                     <h3>Key Features</h3>
                                    
// //                                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
// //                                         <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
// //                                             {grandeurSmartServices.map((item, index) => (
// //                                                 item.keyFeatures.map((expertise, subIndex) => (
// //                                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]">
// //                                                         <strong>{expertise[0]}</strong> {expertise[1]}
// //                                                     </li>
// //                                                 ))
// //                                             ))}
// //                                         </ul>
// //                                     </div> 
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>

                    

// //                     <div className="grid smd:grid-cols-1 grid-cols-2">   
// //                         <div>
// //                             <div>   
// //                                 <div className="px-5">
// //                                     <h3>Benefits</h3>
                                    
// //                                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
// //                                         <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
// //                                             {grandeurSmartServices.map((item, index) => (
// //                                                 item.benefits.map((expertise, subIndex) => (
// //                                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]">
// //                                                         <strong>{expertise[0]}</strong> {expertise[1]}
// //                                                     </li>
// //                                                 ))
// //                                             ))}
// //                                         </ul>
// //                                     </div> 
// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div className="flex justify-center overflow-hidden bg-white">
// //                             <img 
// //                                 src={grandeurmartBenefits}
// //                                 className="object-cover" 
// //                                 alt="Our solutions" 
// //                             />
// //                         </div>
// //                     </div>
// //                 </div>

// //                 <div className="flex justify-center my-5">
// //                     <Link to="/contact" >
// //                         <button className='button-m text-center'>
// //                             Download Technical Document
// //                         </button>
// //                     </Link>
// //                 </div>
// //             </div>

// //             <h1 className="my-10">IOT Egde Gateway Use Cases</h1>

// //             <div className="pb-10 grid grid-cols-1 lg:grid-cols-2">
// //                 {grandeursmartUseCasesData.map((expert, index) => (
// //                 <div key={index} className="rounded-xl flex flex-col flex-1 justify-center bg-white p-5 shadow-xl mx-[5%] border-[1px] border-slate-200 mt-10 ">
// //                     <div >
// //                         <h3 className="leading-normal">{expert.name}</h3>
// //                         <p>{expert.description}</p>
// //                     </div>

// //                     <div className=" mt-5  gap-6">
                        
// //                         <div className="rounded-lg overflow-hidden border-slate-200 border-[1px] ">
                            
// //                             <div className="grid grid-cols-2 w-full items-center p-4">
// //                                 <div>
// //                                     <ul className="list-disc" >
// //                                     {expert.benefits.map((gateway, index) =>(  
                                    
// //                                         <li key={index} className="ml-3">{gateway}</li>
// //                                     ))}
// //                                     </ul>
                                    

// //                                 </div>

// //                                 <div className='relative flex  justify-center items-start h-28'>
// //                                     <img 
// //                                     src={achievement2} 
// //                                     alt={expert.name}
// //                                     className='absolute left-[25%] top-[20%] h-full w-full object-fill' 
                                    
// //                                     />
// //                                     <img 
// //                                     src={achievement1} 
// //                                     alt={expert.name}
// //                                     className='absolute bottom-[0%] h-full w-full mb-6 object-contain' 
                                    
// //                                     />
// //                                 </div>
                                
// //                             </div>
// //                         </div>
                        
// //                     </div>
// //                 </div>
// //                 ))}
// //             </div>
                
// //             <div className="relative grid smd:grid-cols-1 grid-cols-2 items-center gap-4 smd:mx-3 mx-12 py-10  ">     
                        
// //                 <div className="flex flex-col flex-1 justify-between overflow-hidden">
// //                     <h1 className="leading-normal text-left">MultiProtocol Wired and Wireless Connectivity</h1>
// //                     <article className="font-roboto 2xl:text-2xl py-4 2xl:leading-[3rem]">
// //                         GrandeurSmart Gateway is built to support a wide range of wired and wireless communication protocols, ensuring seamless integration with various smart devices and IoT systems.
// //                     </article>
// //                     <p className="font-poppins text-black font-bold">Wireless Protocols</p>
                    
// //                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
// //                         <ul className="list-disc px-6 2xl:text-2xl">
// //                             {grandeursmartMultiprotocolData.map((item, index) => (
// //                                 item.wireless.map((multi, subIndex) => (
// //                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]" >
// //                                         <strong>{multi[0]}</strong> {multi[1]}
// //                                     </li>
// //                                 ))
// //                             ))}
// //                         </ul>
// //                     </div> 
 
// //                 </div>
                    

// //                 <div className="flex flex-col flex-1 justify-center overflow-hidden rounded-3xl">
// //                     <img 
// //                         src={gatewayNew5}
// //                         className="object-cover" 
// //                         alt="Our solutions" 
// //                     />

// //                     <p className="font-poppins text-black font-bold">Wired Protocols</p>  
// //                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
// //                         <ul className="list-disc px-6 2xl:text-2xl">
// //                             {grandeursmartMultiprotocolData.map((item, index) => (
// //                                 item.wired.map((multi, subIndex) => (
// //                                     <li key={`${index}-${subIndex}`} >
// //                                         <strong>{multi[0]}</strong> {multi[1]}
// //                                     </li>
// //                                 ))
// //                             ))}
// //                         </ul>
// //                     </div>
// //                 </div>     
// //             </div>

// //             <div className="flex justify-center my-5">
// //                 <Link to="/contact" >
// //                     <button className='button-m text-center'>
// //                         Download Technical Document
// //                     </button>
// //                 </Link>
// //             </div>

// //             <Footers />
// //         </div>
// //     )
// // }


// import React from "react";
// import Header from "../components/home/Header";
// import Footers from "../components/home/Footers";
// import { achievement1, achievement2, gatewayNew, gatewayNew5, grandeurmartBenefits, grandeursmartFeatures, introBg, iotAppDevelopment, noImage, rto1, } from "../assets";
// import { grandeursmartMultiprotocolData, grandeurSmartServices, grandeursmartUseCasesData, smartBenefit2Data, smartBenefitData, smartData } from "../data/data1";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Helmet } from 'react-helmet-async';
// import { faGlobe } from "@fortawesome/free-solid-svg-icons";
// import { rtosDevelopmentData } from "../data/data3";
// import { Link } from "react-router-dom";


// export default function SmartThings(){

// const product = smartBenefitData[1];

//     return (
//         <div className="bg-slate-100" >
//             <Helmet>
//                 <title>SMART by Mainlogix | AI Personalized Voice Assistant</title>
//                 <meta name="description" content="Discover SMART by Mainlogix, an advanced AI personalized voice assistant that transforms your smart living experience. With features like voice-controlled music playback, smart light management, and the ability to distinguish between humans and animals, SMART delivers seamless interaction with your home and city devices. Explore our versatile IoT gateways, including LoRaWAN to Ethernet/Wi-Fi Gateway with backup battery and Multi-Protocol Smart Gateway with Li-Battery power backup, ensuring reliable connectivity and security for your smart environment." />
//             </Helmet>

//             <Header />

//             <div className="relative w-full ">
//                 <div
//                     className="absolute inset-0 bg-cover bg-center"
//                     style={{ backgroundImage: `url(${introBg})` }}
//                 >
//                     <div className="absolute inset-0 bg-black bg-opacity-75"></div>
//                 </div>
//                 <div className="relative page-title flex items-center justify-between  h-full smd:px-3 px-[10%] smd:py-10 py-20 ">
                
//                     <div >
//                         <h2 className="title-text font-poppins font-semibold text-left text-[55px] text-white">
//                             Mainlogix IOT Edge Gateway
//                         </h2>
//                         <p className="text-white text-left mt-4">
//                             Your Best Smart Gateway For All IOT Use Cases
//                         </p>
//                         <div className="flex justify-start my-5">
//                             <Link to="/contact" >
//                                 <button className='button-m text-center'>
//                                     Download Technical Document
//                                 </button>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>

            
//             <div className="bg-white py-10">
//                 <h1>Mainlogix IOT Edge Gateway </h1>
//                 <p className="text-center smd:px-3 px-[15%] pb-0">
//                     Our IOT Edge Gateway is designed 
//                     to bring all your smart devices together in one easy-to-use 
//                     platform. Whether you're automating your home, managing a 
//                     building, or looking for powerful IoT solutions, GrandeurSmart 
//                     Gateway provides seamless integration, reliable control, and 
//                     advanced security for everything from small homes to large 
//                     estates.
//                 </p>
                

//                 <div className="smd:mx-3 mx-12 mt-5 rounded-xl 2xl:rounded-3xl overflow-hidden border-b-neutral-600 border-[1px] mb-10">

//                     <div className="grid smd:grid-cols-1 grid-cols-2">
//                         <div className="flex justify-center overflow-hidden bg-white">
//                             <img 
//                                 src={grandeursmartFeatures}
//                                 className="object-cover" 
//                                 alt="Our solutions" 
//                             />
//                         </div>
//                         <div>
//                             <div>   
//                                 <div className="px-5"  >
//                                     <h3>Key Features</h3>
                                    
//                                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                                         <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
//                                             {grandeurSmartServices.map((item, index) => (
//                                                 item.keyFeatures.map((expertise, subIndex) => (
//                                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]">
//                                                         <strong>{expertise[0]}</strong> {expertise[1]}
//                                                     </li>
//                                                 ))
//                                             ))}
//                                         </ul>
//                                     </div> 
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

                    

//                     <div className="grid smd:grid-cols-1 grid-cols-2">   
//                         <div>
//                             <div>   
//                                 <div className="px-5">
//                                     <h3>Benefits</h3>
                                    
//                                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                                         <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
//                                             {grandeurSmartServices.map((item, index) => (
//                                                 item.benefits.map((expertise, subIndex) => (
//                                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]">
//                                                         <strong>{expertise[0]}</strong> {expertise[1]}
//                                                     </li>
//                                                 ))
//                                             ))}
//                                         </ul>
//                                     </div> 
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="flex justify-center overflow-hidden bg-white">
//                             <img 
//                                 src={grandeurmartBenefits}
//                                 className="object-cover" 
//                                 alt="Our solutions" 
//                             />
//                         </div>
//                     </div>
//                 </div>

//                 <div className="flex justify-center my-5">
//                     <Link to="/contact" >
//                         <button className='button-m text-center'>
//                             Download Technical Document
//                         </button>
//                     </Link>
//                 </div>
//             </div>

//             <h1 className="my-10">IOT Egde Gateway Use Cases</h1>

//             <div className="pb-10 grid grid-cols-1 lg:grid-cols-2">
//                 {grandeursmartUseCasesData.map((expert, index) => (
//                 <div key={index} className="rounded-xl flex flex-col flex-1 justify-center bg-white p-5 shadow-xl mx-[5%] border-[1px] border-slate-200 mt-10 ">
//                     <div >
//                         <h3 className="leading-normal">{expert.name}</h3>
//                         <p>{expert.description}</p>
//                     </div>

//                     <div className=" mt-5  gap-6">
                        
//                         <div className="rounded-lg overflow-hidden border-slate-200 border-[1px] ">
                            
//                             <div className="grid grid-cols-2 w-full items-center p-4">
//                                 <div>
//                                     <ul className="list-disc" >
//                                     {expert.benefits.map((gateway, index) =>(  
                                    
//                                         <li key={index} className="ml-3">{gateway}</li>
//                                     ))}
//                                     </ul>
                                    

//                                 </div>

//                                 <div className='relative flex  justify-center items-start h-28'>
//                                     <img 
//                                     src={achievement2} 
//                                     alt={expert.name}
//                                     className='absolute left-[25%] top-[20%] h-full w-full object-fill' 
                                    
//                                     />
//                                     <img 
//                                     src={achievement1} 
//                                     alt={expert.name}
//                                     className='absolute bottom-[0%] h-full w-full mb-6 object-contain' 
                                    
//                                     />
//                                 </div>
                                
//                             </div>
//                         </div>
                        
//                     </div>
//                 </div>
//                 ))}
//             </div>
                
//             <div className="relative grid smd:grid-cols-1 grid-cols-2 items-center gap-4 smd:mx-3 mx-12 py-10  ">     
                        
//                 <div className="flex flex-col flex-1 justify-between overflow-hidden">
//                     <h1 className="leading-normal text-left">MultiProtocol Wired and Wireless Connectivity</h1>
//                     <article className="font-roboto 2xl:text-2xl py-4 2xl:leading-[3rem]">
//                         GrandeurSmart Gateway is built to support a wide range of wired and wireless communication protocols, ensuring seamless integration with various smart devices and IoT systems.
//                     </article>
//                     <p className="font-poppins text-black font-bold">Wireless Protocols</p>
                    
//                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                         <ul className="list-disc px-6 2xl:text-2xl">
//                             {grandeursmartMultiprotocolData.map((item, index) => (
//                                 item.wireless.map((multi, subIndex) => (
//                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]" >
//                                         <strong>{multi[0]}</strong> {multi[1]}
//                                     </li>
//                                 ))
//                             ))}
//                         </ul>
//                     </div> 
 
//                 </div>
                    

//                 <div className="flex flex-col flex-1 justify-center overflow-hidden rounded-3xl">
//                     <img 
//                         src={gatewayNew5}
//                         className="object-cover" 
//                         alt="Our solutions" 
//                     />

//                     <p className="font-poppins text-black font-bold">Wired Protocols</p>  
//                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                         <ul className="list-disc px-6 2xl:text-2xl">
//                             {grandeursmartMultiprotocolData.map((item, index) => (
//                                 item.wired.map((multi, subIndex) => (
//                                     <li key={`${index}-${subIndex}`} >
//                                         <strong>{multi[0]}</strong> {multi[1]}
//                                     </li>
//                                 ))
//                             ))}
//                         </ul>
//                     </div>
//                 </div>     
//             </div>

//             <div className="flex justify-center my-5">
//                 <Link to="/contact" >
//                     <button className='button-m text-center'>
//                         Download Technical Document
//                     </button>
//                 </Link>
//             </div>

//             <Footers />
//         </div>
//     )
// }


// import React from "react";
// import Header from "../components/home/Header";
// import Footers from "../components/home/Footers";
// import { achievement1, achievement2, gatewayNew, gatewayNew5, grandeurmartBenefits, grandeursmartFeatures, introBg, iotAppDevelopment, noImage, rto1, } from "../assets";
// import { grandeursmartMultiprotocolData, grandeurSmartServices, grandeursmartUseCasesData, smartBenefit2Data, smartBenefitData, smartData } from "../data/data1";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Helmet } from 'react-helmet-async';
// import { faGlobe } from "@fortawesome/free-solid-svg-icons";
// import { rtosDevelopmentData } from "../data/data3";
// import { Link } from "react-router-dom";


// export default function SmartThings(){

// const product = smartBenefitData[1];

//     return (
//         <div className="bg-slate-100" >
//             <Helmet>
//                 <title>SMART by Mainlogix | AI Personalized Voice Assistant</title>
//                 <meta name="description" content="Discover SMART by Mainlogix, an advanced AI personalized voice assistant that transforms your smart living experience. With features like voice-controlled music playback, smart light management, and the ability to distinguish between humans and animals, SMART delivers seamless interaction with your home and city devices. Explore our versatile IoT gateways, including LoRaWAN to Ethernet/Wi-Fi Gateway with backup battery and Multi-Protocol Smart Gateway with Li-Battery power backup, ensuring reliable connectivity and security for your smart environment." />
//             </Helmet>

//             <Header />

//             <div className="relative w-full ">
//                 <div
//                     className="absolute inset-0 bg-cover bg-center"
//                     style={{ backgroundImage: `url(${introBg})` }}
//                 >
//                     <div className="absolute inset-0 bg-black bg-opacity-75"></div>
//                 </div>
//                 <div className="relative page-title flex items-center justify-between  h-full smd:px-3 px-[10%] smd:py-10 py-20 ">
                
//                     <div >
//                         <h2 className="title-text font-poppins font-semibold text-left text-[55px] text-white">
//                             Mainlogix IOT Edge Gateway
//                         </h2>
//                         <p className="text-white text-left mt-4">
//                             Your Best Smart Gateway For All IOT Use Cases
//                         </p>
//                         <div className="flex justify-start my-5">
//                             <Link to="/contact" >
//                                 <button className='button-m text-center'>
//                                     Download Technical Document
//                                 </button>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>

            
//             <div className="bg-white py-10">
//                 <h1>Mainlogix IOT Edge Gateway </h1>
//                 <p className="text-center smd:px-3 px-[15%] pb-0">
//                     Our IOT Edge Gateway is designed 
//                     to bring all your smart devices together in one easy-to-use 
//                     platform. Whether you're automating your home, managing a 
//                     building, or looking for powerful IoT solutions, GrandeurSmart 
//                     Gateway provides seamless integration, reliable control, and 
//                     advanced security for everything from small homes to large 
//                     estates.
//                 </p>
                

//                 <div className="smd:mx-3 mx-12 mt-5 rounded-xl 2xl:rounded-3xl overflow-hidden border-b-neutral-600 border-[1px] mb-10">

//                     <div className="grid smd:grid-cols-1 grid-cols-2">
//                         <div className="flex justify-center overflow-hidden bg-white">
//                             <img 
//                                 src={grandeursmartFeatures}
//                                 className="object-cover" 
//                                 alt="Our solutions" 
//                             />
//                         </div>
//                         <div>
//                             <div>   
//                                 <div className="px-5"  >
//                                     <h3>Key Features</h3>
                                    
//                                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                                         <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
//                                             {grandeurSmartServices.map((item, index) => (
//                                                 item.keyFeatures.map((expertise, subIndex) => (
//                                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]">
//                                                         <strong>{expertise[0]}</strong> {expertise[1]}
//                                                     </li>
//                                                 ))
//                                             ))}
//                                         </ul>
//                                     </div> 
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

                    

//                     <div className="grid smd:grid-cols-1 grid-cols-2">   
//                         <div>
//                             <div>   
//                                 <div className="px-5">
//                                     <h3>Benefits</h3>
                                    
//                                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                                         <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
//                                             {grandeurSmartServices.map((item, index) => (
//                                                 item.benefits.map((expertise, subIndex) => (
//                                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]">
//                                                         <strong>{expertise[0]}</strong> {expertise[1]}
//                                                     </li>
//                                                 ))
//                                             ))}
//                                         </ul>
//                                     </div> 
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="flex justify-center overflow-hidden bg-white">
//                             <img 
//                                 src={grandeurmartBenefits}
//                                 className="object-cover" 
//                                 alt="Our solutions" 
//                             />
//                         </div>
//                     </div>
//                 </div>

//                 <div className="flex justify-center my-5">
//                     <Link to="/contact" >
//                         <button className='button-m text-center'>
//                             Download Technical Document
//                         </button>
//                     </Link>
//                 </div>
//             </div>

//             <h1 className="my-10">IOT Egde Gateway Use Cases</h1>

//             <div className="pb-10 grid grid-cols-1 lg:grid-cols-2">
//                 {grandeursmartUseCasesData.map((expert, index) => (
//                 <div key={index} className="rounded-xl flex flex-col flex-1 justify-center bg-white p-5 shadow-xl mx-[5%] border-[1px] border-slate-200 mt-10 ">
//                     <div >
//                         <h3 className="leading-normal">{expert.name}</h3>
//                         <p>{expert.description}</p>
//                     </div>

//                     <div className=" mt-5  gap-6">
                        
//                         <div className="rounded-lg overflow-hidden border-slate-200 border-[1px] ">
                            
//                             <div className="grid grid-cols-2 w-full items-center p-4">
//                                 <div>
//                                     <ul className="list-disc" >
//                                     {expert.benefits.map((gateway, index) =>(  
                                    
//                                         <li key={index} className="ml-3">{gateway}</li>
//                                     ))}
//                                     </ul>
                                    

//                                 </div>

//                                 <div className='relative flex  justify-center items-start h-28'>
//                                     <img 
//                                     src={achievement2} 
//                                     alt={expert.name}
//                                     className='absolute left-[25%] top-[20%] h-full w-full object-fill' 
                                    
//                                     />
//                                     <img 
//                                     src={achievement1} 
//                                     alt={expert.name}
//                                     className='absolute bottom-[0%] h-full w-full mb-6 object-contain' 
                                    
//                                     />
//                                 </div>
                                
//                             </div>
//                         </div>
                        
//                     </div>
//                 </div>
//                 ))}
//             </div>
                
//             <div className="relative grid smd:grid-cols-1 grid-cols-2 items-center gap-4 smd:mx-3 mx-12 py-10  ">     
                        
//                 <div className="flex flex-col flex-1 justify-between overflow-hidden">
//                     <h1 className="leading-normal text-left">MultiProtocol Wired and Wireless Connectivity</h1>
//                     <article className="font-roboto 2xl:text-2xl py-4 2xl:leading-[3rem]">
//                         GrandeurSmart Gateway is built to support a wide range of wired and wireless communication protocols, ensuring seamless integration with various smart devices and IoT systems.
//                     </article>
//                     <p className="font-poppins text-black font-bold">Wireless Protocols</p>
                    
//                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                         <ul className="list-disc px-6 2xl:text-2xl">
//                             {grandeursmartMultiprotocolData.map((item, index) => (
//                                 item.wireless.map((multi, subIndex) => (
//                                     <li key={`${index}-${subIndex}`} className="2xl:leading-[3rem]" >
//                                         <strong>{multi[0]}</strong> {multi[1]}
//                                     </li>
//                                 ))
//                             ))}
//                         </ul>
//                     </div> 
 
//                 </div>
                    

//                 <div className="flex flex-col flex-1 justify-center overflow-hidden rounded-3xl">
//                     <img 
//                         src={gatewayNew5}
//                         className="object-cover" 
//                         alt="Our solutions" 
//                     />

//                     <p className="font-poppins text-black font-bold">Wired Protocols</p>  
//                     <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                         <ul className="list-disc px-6 2xl:text-2xl">
//                             {grandeursmartMultiprotocolData.map((item, index) => (
//                                 item.wired.map((multi, subIndex) => (
//                                     <li key={`${index}-${subIndex}`} >
//                                         <strong>{multi[0]}</strong> {multi[1]}
//                                     </li>
//                                 ))
//                             ))}
//                         </ul>
//                     </div>
//                 </div>     
//             </div>

//             <div className="flex justify-center my-5">
//                 <Link to="/contact" >
//                     <button className='button-m text-center'>
//                         Download Technical Document
//                     </button>
//                 </Link>
//             </div>

//             <Footers />
//         </div>
//     )
// }

import React, { useState, useEffect, useRef } from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { achievement1, achievement2, gateway, gatewayA, gatewayB, introBg } from "../assets";
import { grandeurSmartFeaturesData, grandeurSmartUseCasesData } from "../data/data4";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { grandeursmartUseCasesData } from "../data/data1";


export default function SmartThings() {
    const [expandedCardIndex, setExpandedCardIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalTopic, setModalTopic] = useState('');
    const [modalDescription, setModalDescription] = useState('');
    const [popModal, setPopModal] = useState(false);

    const modalRef = useRef();

    const handleReadMoreClick = (index, title, description, des1, list1, des2, list2) => {
        let fullDescription = description;

        if (des1) {
            fullDescription += `<br/><strong>${des1}</strong><ul>`;
            list1.forEach(item => {
                fullDescription += `<li><strong>${item[0]}</strong> ${item[1]}</li>`;
            });
            fullDescription += `</ul>`;
        }

        if (des2) {
            fullDescription += `<br/><strong>${des2}</strong><ul>`;
            list2.forEach(item => {
                fullDescription += `<li><strong>${item[0]}</strong> ${item[1]}</li>`;
            });
            fullDescription += `</ul>`;
        }

        setExpandedCardIndex(index);
        setModalTitle(title);
        setModalContent(fullDescription);
        setPopModal(false);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPopModal(false);
        setExpandedCardIndex(null);
    };

    const getShortDescription = (description) => {
        const words = description.split(' ');
        if (words.length > 10) {
            return words.slice(0, 10).join(' ') + '...';
        }
        return description;
    };

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.keyCode === 27) {
                handleCloseModal();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleCloseModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Open modal with the full description
  const handleReadMore = (topic, des) => {
    setModalTopic(topic);
    setModalDescription(des);
    setShowModal(false);
    setPopModal(true);
  };

  // Shorten description if it's longer than 10 words
  const getDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + "...";
    }
    return description;
  };

    return (
        <div className="bg-slate-100">
            <Helmet>
                <title>GrandeurSmart by Mainlogix | IOT Egde Gateway</title>
                <meta name="description" content="Discover SMART by Mainlogix, an advanced AI personalized voice assistant..." />
            </Helmet>

            <Header />

            <div className="relative w-full">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                <div className="relative page-title flex items-center justify-between h-full smd:px-3 px-[10%] smd:py-10 py-20">
                    <div>
                        <h2 className="title-text font-poppins font-semibold text-left text-[55px] text-white">
                            Mainlogix IOT Edge Gateway
                        </h2>
                        <p className="text-white text-left mt-4">
                            Your Best Smart Gateway For All IOT Use Cases
                        </p>
                        <div className="flex justify-start my-5">
                            <Link to="/contact">
                                <button className='button-m text-center'>
                                    Download Technical Document
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white py-10 px-[5%]">
                <h1>Mainlogix IOT Edge Gateway</h1>
                <p className="text-center smd:px-3 px-[15%] pb-0">
                    Our IOT Edge Gateway is designed 
                     to bring all your smart devices together in one easy-to-use 
                     platform. Whether you're automating your home, managing a 
                     building, or looking for powerful IoT solutions, GrandeurSmart 
                     Gateway provides seamless integration, reliable control, and 
                     advanced security for everything from small homes to large 
                     estates.
                </p>

                <div className="grid smd:grid-cols-1 lg:grid-cols-[0.7fr_0.3fr] items-center gap-4 py-5">
                    <div className="flex justify-center rounded-lg bg-white">
                        <img src={gateway} className="rounded-xl object-fill" alt="Our solutions" />
                    </div>

                    <div>
                        <h3 className="leading-normal text-black py-5">Key Features</h3>

                        <div className="grid gap-6 smd:py-4 md:grid-cols-2 mb-5">
                            {grandeurSmartFeaturesData.map((card, index) => (
                                <div key={index}>
                                    <div
                                        className={`rounded-lg border-[1px] border-gray-300 hover:shadow-xl transition-shadow duration-500 overflow-hidden mb-5`}
                                    >
                                        <div className="p-4 flex flex-col justify-center h-full">
                                            <h3 className="text-center leading-normal md:text-sm lg:text-sm 2xl:text-lg 3xl:text-2xl">{card.title}</h3>
                                            <p className="text-center">
                                                {getShortDescription(card.des)}
                                            </p>
                                            <button
                                                className="text-blue-500 mt-2"
                                                onClick={() => handleReadMoreClick(index, card.title, card.des, card.des1, card.list1, card.des2, card.list2)}
                                            >
                                                Read More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center my-5">
                    <Link to="/contact">
                        <button className='button-m text-center'>Download Technical Document</button>
                    </Link>
                </div>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div ref={modalRef} className="bg-white p-5 rounded-md shadow-lg max-w-lg w-full h-[70%] overflow-y-auto">
                        <h2 className="text-center leading-normal md:text-sm lg:text-sm 2xl:text-lg 3xl:text-2xl font-bold">{modalTitle}</h2>
                        <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
                        <button className="mt-4 text-blue-500" onClick={handleCloseModal}>Close</button>
                    </div>
                </div>
            )}

            <div>
                <h1 className="pt-10">Benefits for Custom Use Cases</h1>
                <div className="flex justify-center items-center">
                    <img src={gatewayB} alt="IOT Edge Gateway"/>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 smd:mx-[15%] mx-[5%] gap-4">
                {grandeurSmartUseCasesData.map((uses, index) => (
                    <div
                    key={index}
                    className="relative flex flex-col h-full w-full"
                    >
                    <div className="p-5 group relative border border-gray-200 rounded-lg overflow-hidden transition-shadow duration-500 hover:shadow-xl flex flex-col justify-between min-h-[300px] h-full w-full">
                        {/* Top red border animation */}
                        <div className="absolute top-0 left-0 h-1 bg-red-500 w-0 group-hover:w-full transition-all duration-500"></div>
                        
                        {/* Title */}
                        <h3 className="text-center leading-normal md:text-sm lg:text-sm 2xl:text-lg 3xl:text-2xl mb-2">
                        {uses.title}
                        </h3>

                        {/* Description */}
                        <p className="transition-opacity duration-500  group-hover:opacity-100">
                        {getDescription(uses.des)}
                        </p>

                        {/* Show Read More Icon if description is longer than 10 words */}
                        {uses.des.split(" ").length > 10 && (
                        <div className="flex justify-center mt-2">
                            <button
                            className="text-blue-500 group-hover:scale-125 transition-transform duration-300"
                            onClick={() => handleReadMore(uses.title, uses.des)}
                            >
                            Read More
                            </button>
                        </div>
                        )}
                    </div>
                    </div>
                ))}

                {/* Modal for Full Description */}
                {popModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div
                        ref={modalRef}
                        className="bg-white p-5 rounded-md shadow-lg max-w-lg w-full overflow-y-scroll"
                    >
                        <h2 className="text-xl mb-4">{modalTopic}</h2>
                        <p>{modalDescription}</p>
                        <button className="mt-4 text-blue-500" onClick={handleCloseModal}>
                        Close
                        </button>
                    </div>
                    </div>
                )}
                </div>

                

            </div>


            <h1 className="mt-10">IOT Egde Gateway Use Cases</h1>

            <div className="pb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                 {grandeursmartUseCasesData.map((expert, index) => (
                <div key={index} className="rounded-xl flex flex-col flex-1  justify-between bg-white p-5 shadow-xl mx-[5%] border-[1px] border-slate-200 mt-10 ">
                    <div >
                        <h3 className="leading-normal">{expert.name}</h3>
                        <p>{expert.description}</p>
                    </div>

                    <div className=" mt-5  gap-6">
                        
                        <div className="rounded-lg overflow-hidden border-slate-200 border-[1px] ">
                            
                            <div className="grid smd:grid-cols-1 grid-cols-1 w-full h-[300px] 2xl:h-[450px] items-center ">
                            <div className='flex  justify-center items-start'>
                                    <img 
                                    src={expert.images[0]} 
                                    alt={expert.name}
                                    className=' h-[150px] 2xl:h-[300px] w-full object-cover' 
                                    
                                    />
                                    
                                </div>
                                <div>
                                    <ul className="list-disc ml-4" >
                                    {expert.benefits.map((gateway, index) =>(  
                                    
                                        <li key={index} className="ml-3">{gateway}</li>
                                    ))}
                                    </ul>
                                    

                                </div>

                                
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                ))}
            </div>

            <Footers />
        </div>
    );
}

